import React from 'react';
import { Main } from 'pages/main';
import { ErrorPage } from 'pages/errorPage';
import { AppPaths } from 'router/paths/appPaths';
import { SignUp } from 'pages/auth/pages/signup';
import { SignIn } from 'pages/auth/pages/signin';
import { Groups } from 'pages/main/pages/groups';
import { Profile } from 'pages/auth/pages/profile';
import { Payment } from 'pages/main/pages/payment';
import { MainLayout } from 'containers/layout/mainLayout';
import { AuthLayout } from 'containers/layout/authlayout';
import { MoreFunctions } from 'pages/main/pages/moreFunctions';
import { SignUpSuccess } from 'pages/auth/pages/signupSuccess';
import { SignInSuccess } from 'pages/auth/pages/signinSuccess';
import { EmployeesList } from 'pages/main/pages/employeesList';
import { ForgotPassword } from 'pages/auth/pages/forgotPassword';
import { ChangePassword } from 'pages/auth/pages/changePassword';
import { PaymentHistory } from 'pages/main/pages/paymentHistory';
import { CreateGroup } from 'pages/main/pages/groups/pages/createGroup';
import { GroupsDetail } from 'pages/main/pages/groups/pages/groupsDetail';
import { ChangePasswordSuccess } from 'pages/auth/pages/changePasswordSuccess';
import { CreateEmployee } from 'pages/main/pages/employeesList/pages/createEmployee';
import { EmployeesDetail } from 'pages/main/pages/employeesList/pages/employeesDetail';
import { PaymentConfirmation } from 'pages/main/pages/payment/pages/paymentConfirmation';
import { HistoryDetail } from 'pages/main/pages/paymentHistory/pages/paymentHistoryDetail';
import { MoreFunctionsDetail } from 'pages/main/pages/moreFunctions/pages/moreFunctionsDetail';
import {
  ImportPaymentFileFunc
} from 'pages/main/pages/moreFunctions/pages/moreFunctionsDetail/components/importPaymentFile';

export const routes = [
  {
    index: true,
    path: AppPaths.main,
    label: 'Main',
    component: () => (
      <MainLayout>
        <Main />
      </MainLayout>
    ),

    routes: [
      {
        path: AppPaths.profile,
        label: 'Profile',
        index: true,
        component: () => (
          <MainLayout>
            <Profile />
          </MainLayout>
        )
      },
      {
        path: AppPaths.employeesList,
        label: 'Employees list',
        index: true,
        component: () => (
          <MainLayout>
            <EmployeesList />
          </MainLayout>
        ),

        routes: [
          {
            path: AppPaths.employeesDetail,
            label: 'Employees detail',
            index: true,
            component: () => (
              <MainLayout>
                <EmployeesDetail />
              </MainLayout>
            )
          },

          {
            path: AppPaths.createEmployee,
            label: 'Create employee',
            index: true,
            component: () => (
              <MainLayout>
                <CreateEmployee />
              </MainLayout>
            )
          },
        ]
      },
      {
        path: AppPaths.paymentHistory,
        label: 'Payment history',
        index: true,
        component: () => (
          <MainLayout>
            <PaymentHistory />
          </MainLayout>
        ),
        routes: [
          {
            path: AppPaths.paymentHistoryDetail,
            label: 'Payment details',
            index: true,
            component: () => (
              <MainLayout>
                <HistoryDetail />
              </MainLayout>
            )
          }
        ]
      },
      {
        path: AppPaths.payment,
        label: 'Payment',
        index: true,
        component: () => (
          <MainLayout>
            <Payment />
          </MainLayout>
        ),
        routes: [
          {
            path: AppPaths.paymentСonfirmation,
            label: 'Payment confirmation',
            index: true,
            component: () => (
              <MainLayout>
                <PaymentConfirmation />
              </MainLayout>
            )
          },
        ]
      },
      {
        path: AppPaths.groups,
        label: 'Groups',
        index: true,
        component: () => (
          <MainLayout>
            <Groups />
          </MainLayout>
        ),
        routes: [
          {
            path: AppPaths.groupsDetail,
            label: 'Groups detail',
            index: true,
            component: () => (
              <MainLayout>
                <GroupsDetail />
              </MainLayout>
            )
          },
          {
            path: AppPaths.createGroup,
            label: 'Create group',
            index: true,
            component: () => (
              <MainLayout>
                <CreateGroup />
              </MainLayout>
            )
          },
        ]
      },
      {
        path: AppPaths.moreFunctions,
        label: 'More functions',
        index: true,
        component: () => (
          <MainLayout>
            <MoreFunctions />
          </MainLayout>
        ),
        routes: [
          {
            path: AppPaths.moreFunctionsDetail,
            label: 'Import function',
            index: true,
            component: () => (
              <MainLayout>
                <MoreFunctionsDetail>
                  <ImportPaymentFileFunc />
                </MoreFunctionsDetail>
              </MainLayout>
            )
          },
        ]
      },
      {
        path: AppPaths.signin,
        label: 'Sign In',
        index: true,
        component: () => (
          <AuthLayout>
            <SignIn />
          </AuthLayout>
        )
      },
      {
        path: AppPaths.signup,
        index: true,
        label: 'Register',
        component: () => (
          <AuthLayout>
            <SignUp />
          </AuthLayout>
        )
      },
      {
        path: AppPaths.signupSuccess,
        index: true,
        label: 'Register success',
        component: () => (
          <AuthLayout>
            <SignUpSuccess />
          </AuthLayout>
        )
      },
      {
        path: AppPaths.signinSuccess,
        index: true,
        label: 'Login success',
        component: () => (
          <AuthLayout>
            <SignInSuccess />
          </AuthLayout>
        )
      },
      {
        path: AppPaths.forgotPassword,
        label: 'Forgot your password?',
        index: true,
        component: () => (
          <AuthLayout>
            <ForgotPassword />
          </AuthLayout>
        )
      },
      {
        path: AppPaths.changePassword,
        label: 'Change password',
        index: true,
        component: () => (
          <AuthLayout>
            <ChangePassword />
          </AuthLayout>
        )
      },
      {
        path: AppPaths.successPassword,
        label: 'Success password',
        index: true,
        component: () => (
          <AuthLayout>
            <ChangePasswordSuccess />
          </AuthLayout>
        )
      }
    ]
  },
  {
    index: true,
    path: AppPaths.NotFound,
    component: () => (
      <AuthLayout>
        <ErrorPage status={'not found'} text={'Sorry! The page you are looking for could not be found'} />
      </AuthLayout>
    )
  },
  {
    index: true,
    path: AppPaths.NotFound404,
    component: () => (
      <AuthLayout>
        <ErrorPage status={404} text={'Sorry! The resource requested could not be found on this server. Contact administrator please.'} />
      </AuthLayout>
    )
  },
  {
    index: true,
    path: AppPaths.InternalError500,
    component: () => (
      <AuthLayout>
        <ErrorPage status={500} text={'Sorry! Internal server error'} />
      </AuthLayout>
    )
  },
  {
    index: true,
    path: AppPaths.Error504,
    component: () => (
      <AuthLayout>
        <ErrorPage status={504} text={'Sorry! Server Time Out'} />
      </AuthLayout>
    )
  },
  {
    index: true,
    path: AppPaths.Error403,
    component: () => (
      <AuthLayout>
        <ErrorPage status={403} text={'Sorry! Access to the requested resource is denied'} />
      </AuthLayout>
    )
  },
  {
    index: true,
    path: AppPaths.Error502,
    component: () => (
      <AuthLayout>
        <ErrorPage
          status={502}
          text={
            'Sorry! The server was unable to process the received request for technical reasons'
          }
        />
      </AuthLayout>
    )
  }
];
