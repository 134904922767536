/* eslint-disable prefer-const,no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { useStores } from 'store';
import { motion } from 'framer-motion';
import { Loader } from 'common/loader';
import { observer } from 'mobx-react-lite';
import { LogoSVG } from 'assets/icons/logo';
import { DropSVG } from 'assets/icons/drop';
import { CrossSVG } from 'assets/icons/cross';
import { useTranslation } from 'react-i18next';
import { BurgerSVG } from 'assets/icons/burger';
import { AvatarSVG } from 'assets/icons/avatar';
import { useLocation, useNavigate } from 'react-router';
import { RouterLink } from 'router/components/routerLink';
import useWindowDimensions from 'hooks/useWindowDimensions.hook';
import { SideMenuButton } from 'containers/header/components/sideMenuBtn/SideMenuButton';
import { menuTabsListOfClients, navigatePath } from 'common/constants/header';
import s from './index.module.scss';
import { Loading } from '../../store/types';
 
const HeaderView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [activeContent, setActiveContent] = useState<any>({ path: location.pathname });
  const [checkPath, setCheckPath] = useState<string>('');
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [isListSideMenuOpen, setIsListSideMenuOpen] = useState<boolean>(false);
  const { auth, payments } = useStores();
  const { isTablet } = useWindowDimensions();
  const { getAuthModel } = auth;
  const { getPaymentsModel } = payments;
  const { getAuthMe } = getAuthModel;
  const [isProfileListOpen, setIsProfileListOpen] = useState(false);
  const [isHeaderNavigateModal, setIsHeaderNavigateModal] = useState(false);
  const burgerWindowEl = useRef<any>();
  const burgerButtonEl = useRef<any>();
  const moreBtnEl = useRef<any>();
  const moreBtnSideMenuEl = useRef<any>();
  const profileBtnEl = useRef<any>();
  const profileListEl = useRef<any>();
  const [temporaryBalance, setTemporaryBalance] = useState<string | null>(sessionStorage.getItem('temporaryBalance') === 'null' ? null : sessionStorage.getItem('temporaryBalance'));
  const handleClick = (elements: any) => {
    setActiveContent(elements);
    setCheckPath(elements.path);
  };

  const logout = () => {
    auth.logoutAction();
    navigate('/signin');
  };

  const handleMoreList = () => {
    setIsListOpen(!isListOpen);
  };

  const handleMoreListSideMenu = () => {
    setIsListSideMenuOpen(!isListSideMenuOpen);
  };

  useEffect(() => {
    if (activeContent?.path === checkPath) {
      navigate(activeContent?.path);
    }
  }, [activeContent]);

  useEffect(() => {
    const isOpenInteractiveList = (e: any) =>
      burgerButtonEl?.current?.contains(e.target) ||
      burgerWindowEl?.current?.contains(e.target) ||
      profileBtnEl?.current?.contains(e.target) ||
      profileListEl?.current?.contains(e.target) ||
      setIsHeaderNavigateModal(false) ||
      setIsProfileListOpen(false);

    document.addEventListener('click', isOpenInteractiveList);
    return () => document.removeEventListener('click', isOpenInteractiveList);
  }, []);



  useEffect(() => {

    if (isHeaderNavigateModal && !isTablet) {
      document.body.style.overflow = 'hidden';
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
      document.getElementsByTagName('html')[0].style.overflow = '';
    }

  }, [isHeaderNavigateModal]);

  useEffect(() => {
    if (getPaymentsModel.getPaymentsTemporaryBalance.active) {
      setTemporaryBalance(null);
      getPaymentsModel.setPaymentsTemporaryBalance({ temporaryBalance: null, active: false });
    }
  }, [getPaymentsModel.getPaymentsTemporaryBalance]);

  return (
    <section className={s.wrapper}>
      <div className={s.wrapper__container}>
        <div ref={burgerButtonEl} className={s.burger}>
          <button
            onClick={() => setIsHeaderNavigateModal(!isHeaderNavigateModal)}
            type="button"
            className={s.burger__button}
          >
            <BurgerSVG />
          </button>
        </div>

        <div className={s.wrapper__logo}>
          <RouterLink to="/employees">
            <button type="button">
              <LogoSVG />
            </button>
          </RouterLink>
        </div>

        <div
          className={`${s.wrapper__buttons} ${
            activeContent?.path.includes(...navigatePath)
              ? s.employees_active
              : ''
          }`}
        >
          {menuTabsListOfClients(t).map((menuTab: any, index: number) =>
            <div className={[s.wrapper__buttons_inner, menuTab.moreBtn ? s.more_btn_inner : null, isListOpen && menuTab.moreBtn ? s.more_btn_active : null ].join(' ')} key={menuTab.index}>
              <div key={menuTab.index} className={`${s.wrapper__button} ${menuTab.moreBtn ? s.more_btn : ''} ${isListOpen && menuTab.moreBtn ? s.wrapper__button_list : ''} ${ menuTab.specificIcon ? s.specific_icon : ''} ${menuTab.defaultIcon ? s.default_icon : ''}`}>
                <button
                  className={[s.button, menuTab.moreBtn ? s.button_more : null, (activeContent?.path.includes(menuTab.path) || (isListOpen && menuTab.moreBtn)) ? s.button_active : s.button_default].join(' ')}
                  ref={menuTab.moreBtn ? moreBtnEl : null}
                  type="button"
                  onClick={() =>
                    !menuTab.moreBtn ? handleClick({
                      index,
                      titles: menuTab.title,
                      icon: menuTab.icon,
                      path: menuTab.path
                    }) : handleMoreList()

                  }
                >
                  {menuTab.icon}
                  <p>{menuTab.title}</p>
                </button>
              </div>
              {/* {menuTab.moreBtn ? <MoreFunctionsList isOpen={isListOpen} setIsOpen={setIsListOpen} btnEl={moreBtnEl} /> : null} */}
            </div>
          )}
        </div>

        <div className={s.profile}>
          <div className={s.profile__balance}>
            {
              temporaryBalance
                ?
                <p>{Number(temporaryBalance)?.toFixed(2)?.toLocaleString().toLocaleString()} UGX</p>
                :
                getAuthMe?.balance?.amount ? <p>{Number(getAuthMe?.balance?.amount)?.toFixed(2)?.toLocaleString()} UGX</p> : !getAuthMe?.balance?.amount ? <p>{Number(0)?.toFixed(2)?.toLocaleString()} UGX</p> : <Loader />
            }
          </div>

          <div ref={profileBtnEl} className={s.profile__wrapper}>
            <button
              onClick={() => setIsProfileListOpen(!isProfileListOpen)}
              className={s.profile__button}
              type="button"
              style={
                isProfileListOpen
                  ? { borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }
                  : {}
              }
            >
              <div className={s.profile__icon}>
                <AvatarSVG />
              </div>
              <p>{getAuthMe.email || t<string>('MAIN.HEADER.MENU.PALCEHOLDERNAME')}</p>
              <div className={`${s.arrow} ${isProfileListOpen ? s.arrow_active : ''}`}>
                <DropSVG />
              </div>
            </button>
            <div
              ref={profileListEl}
              style={
                isProfileListOpen
                  ? { display: 'flex', borderTopRightRadius: '0', borderTopLeftRadius: '0' }
                  : { display: 'none' }
              }
              className={s.profile__list}
            >
              <div className={s.balance}>
                {
                  temporaryBalance
                    ?
                    <p>{Number(temporaryBalance)?.toFixed(2)?.toLocaleString()} UGX</p>
                    :
                    getAuthMe?.balance?.amount ? <p>{Number(getAuthMe?.balance?.amount)?.toFixed(2)?.toLocaleString()} UGX</p> : !getAuthMe?.balance?.amount ? <p>{Number(0)?.toFixed(2)?.toLocaleString()} UGX</p> : <Loader />
                }
              </div>

              <button className={s.logout} onClick={logout} type="button">
                <p>{t<string>('MAIN.HEADER.MENU.EXIT')}</p>
              </button>
            </div>
          </div>
        </div>
      </div>

      <motion.div
        initial="hidden"
        animate={isHeaderNavigateModal ? 'open' : 'hidden'}
        variants={{
          hidden: { width: 0, opacity: 0 },
          open: { width: !isTablet ? '100%' : '15.8125rem', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)', opacity: 1 }
        }}
        transition={{ duration: 0.3, type: 'spring', damping: 18 }}
        className={s.employee__window}
        ref={burgerWindowEl}
      >
        <motion.div
          initial="hidden"
          animate={isHeaderNavigateModal ? 'open' : 'hidden'}
          variants={{
            hidden: { display: 'none' },
            open: { display: 'flex' }
          }}
          transition={{ duration: 0.3, type: 'spring', damping: 18 }}
          className={s.employee__window__inner}>
          <div className={s.header}>
            <div className={s.header__title}>
              <h2 className={s.title}>{t<string>('MAIN.HEADER.MENU.TITLE')}</h2>
              <div className={s.close}>
                <button type="button" onClick={() => setIsHeaderNavigateModal(false)}>
                  <CrossSVG />
                </button>
              </div>
            </div>
            <div
              className={`${s.wrapper__buttons_modal} ${
                activeContent?.path.includes(...navigatePath)
                  ? s.employees_active_modal
                  : ''
              }`}
            >
              {menuTabsListOfClients(t).map((menuTab: any, index: number) =>
                <div key={menuTab.index} className={`${s.wrapper__button} ${menuTab.moreBtn ? s.more_btn : ''} ${isListSideMenuOpen && menuTab.moreBtn ? s.wrapper__button_list : ''} ${ menuTab.specificIcon ? s.specific_icon : ''} ${menuTab.defaultIcon ? s.default_icon : ''}`}>
                  <SideMenuButton buttonEl={moreBtnSideMenuEl} activeContent={activeContent} index={index} menuTab={menuTab} handleClick={menuTab.path ? handleClick : handleMoreListSideMenu } />
                  {/* {menuTab.moreBtn ? <MoreFunctionsList styles={{ width: '100%' }} isOpen={isListSideMenuOpen} setIsOpen={setIsListSideMenuOpen} btnEl={moreBtnSideMenuEl} /> : null} */}
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </motion.div>
    </section>
  );
};

export const Header = observer(HeaderView);
