/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable class-methods-use-this */
/* eslint-disable */
import axios, { AxiosError, AxiosResponse, AxiosInstance, AxiosRequestHeaders } from 'axios';
import { Navigate } from 'react-router';
import { tokenFieldName } from 'common/constants/auth/index';
import { ApiMessageError } from './apiErrorModel';

class RestAPI {
  url: string;

  tokenName: string;

  constructor(tokenName: string = tokenFieldName) {
    this.url = process.env.REACT_APP_API_URL || '';
    this.tokenName = tokenName;
  }

  handleSuccess = (response: AxiosResponse): AxiosResponse => response;

  handleError = (error: AxiosError<any>): Promise<object> | void | any => {
    let encodedData: string | null = null;
    let newPath: string | null = null;
    switch (error?.response?.status) {
      case 404:
        encodedData = encodeURIComponent(JSON.stringify(error?.response?.data?.error));
        newPath = `/404/${encodedData}`;
        window.location.pathname = newPath;
        return Promise.reject(error);
      case 401:
        window.location.pathname = '/signin';
        return Promise.reject(new ApiMessageError('Authorization error'));
      case 500:
        encodedData = encodeURIComponent(JSON.stringify(new ApiMessageError('Internal server error').message));
        newPath = `/500/${encodedData}`;
        window.location.pathname = newPath;
        return Promise.reject(new ApiMessageError('Internal server error'));
      case 502:
        encodedData = encodeURIComponent(JSON.stringify(new ApiMessageError('The server was unable to process the received request for technical reasons').message));
        newPath = `/502/${encodedData}`;
        window.location.pathname = newPath;
        return Promise.reject(new ApiMessageError('The server was unable to process the received request for technical reasons'));
      case 504:
        encodedData = encodeURIComponent(JSON.stringify(new ApiMessageError('Server Time Out').message));
        newPath = `/504/${encodedData}`;
        window.location.pathname = newPath;
        return Promise.reject(new ApiMessageError('Server Time Out'));
      case 409:
        return Promise.reject(new ApiMessageError('Wrong OTP'));
      case 403:
        encodedData = encodeURIComponent(JSON.stringify(new ApiMessageError('Access to the requested resource is denied').message));
        newPath = `/403/${encodedData}`;
        window.location.pathname = newPath;
        return Promise.reject(new ApiMessageError('Access to the requested resource is denied'));
      case 400:
        return Promise.reject(error);
      default:
        break;
    }
    return Promise.reject('Something went wrong, сontact administrator');
  };

  private create = (headers?: any): AxiosInstance => {
    const cancel = axios.CancelToken.source();
    const currency = localStorage.getItem('currencycharcode');
    const service = axios.create({
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': `${window.location.protocol}//${window.location.host}`,
        ...headers
      },
      cancelToken: cancel.token
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    return service;
  };

  public get = <T>(
    path: string = '',
    params?: T,
    headers?: AxiosRequestHeaders
  ): Promise<AxiosResponse> => {
    const service = this.create(headers);
    return service.request({
      method: 'GET',
      url: `${this.url}${path}`,
      params
    });
  };

  public post = <T>(
    path: string,
    data: T,
    headers?: AxiosRequestHeaders
  ): Promise<AxiosResponse> => {
    const service = this.create(headers);
    return service.request({
      method: 'POST',
      url: `${this.url}${path}`,
      data
    });
  };

  public put = <T>(
    path: string,
    data?: T,
    params?: T,
    headers?: AxiosRequestHeaders
  ): Promise<AxiosResponse> => {
    const service = this.create(headers);
    return service.request({
      method: 'PUT',
      url: `${this.url}${path}`,
      data: {
        ...data
      },
      params
    });
  };

  public delete = <T>(
    path: string,
    data: T,
    params: object = {},
    headers?: AxiosRequestHeaders
  ): Promise<AxiosResponse> => {
    const service = this.create(headers);
    return service.request({
      method: 'DELETE',
      url: `${this.url}${path}`,
      data: {
        ...data
      },
      params
    });
  };

  public patch = <T>(
    path: string,
    data: T,
    params: object = {},
    headers?: AxiosRequestHeaders
  ): Promise<AxiosResponse> => {
    const service = this.create(headers);
    return service.request({
      method: 'PATCH',
      url: `${this.url}${path}`,
      data: {
        ...data
      },
      params
    });
  };
}

export default new RestAPI();